$(function() {
    // Footer mobile menu
    $(".footer ul.columns > li").on("click", function () {
        $(this).toggleClass("isActive");
    });

    // Toggle main menu
    $("#toggleMenu").on("click", function (event) {
        event.preventDefault();
        $(this).toggleClass("isActive");
        $("body").toggleClass("noScroll");

        $menu = $("ul.menu");
        $overlay = $(".bg-overlay");

        $menu.css('display', '');
        $menu.toggleClass("is-visible");
        $overlay.fadeToggle();
    });

    $('.introSlider').slick({
        adaptiveHeight: true,
        autoplaySpeed: 3500,
        slidesToShow: 1,
        autoplay: true,
        dots: false,
        nextArrow: '<button type="button" class="slick-arrow slick-next"></button>',
        prevArrow: '<button type="button" class="slick-arrow slick-prev"></button>'
    });

    $('.regularPacks').slick({
        slidesToScroll: 1,
        slidesToShow: 4,
        autoplay: true,
        dots: false,
        responsive: [
            {
                breakpoint: 1160,
                settings: {
                    slidesToShow: 3,
                    dots: false,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                    dots: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                }
            }
        ]
    });
});