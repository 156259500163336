function createCookie(name, value, days) {
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		var expires = "; expires=" + date.toGMTString();
	}
	else var expires = "";
	// document.cookie = name + "=" + value + expires + ";secure;domain=.gd.ro; path=/";
	document.cookie = name + "=" + value + expires + ";secure;domain=.iqos.ro; path=/";
}

function readCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

function eraseCookie(name) {
	createCookie(name, "", -1);
}

function check_scroll(e) {
    var elem = $(e.currentTarget);
    if (Math.floor(elem[0].scrollHeight - elem.scrollTop()) <= elem.outerHeight() + 1000) {
        elem.parent().find(".button").removeAttr("disabled");

        $(".agree").on("click", function(){
            $(".terms").scrollTop(0);
            $(".agree").attr('disabled', true);
            $("body").removeClass("noScroll");
            $(".pp-body").hide();
            $(".bg-overlay").hide();
            $(".hm-trigger").toggle();
        });
    }
}

function goToByScroll(id){
    id = id.replace("link", "");
    $('html,body').animate({ scrollTop: $("#"+id).offset().top}, 'slow');
}

var disclaimer = '<div class="cookieDisclaimer">' +
					'<div class="container">' +
						// '<div class="close-disclaimer"></div>' +
						'<p>Folosim cookies pentru a-ți oferi o experiență personalizată de comunicare și pentru a-ți oferi publicitate relevantă, bazată atât pe comportamentul tău pe acest site, cât și\n' +
                        'pe altele, pentru optimizarea platformei, funcționarea corectă a acesteia și pentru a reține preferințele tale. Apasă <b>“Află mai multe”</b> pentru detalii sau pentru a-ți ajusta\n' +
                        'preferințele. Te poți răzgândi oricând apăsând butonul “Gestionează cookies.” ' +
                        '<br><br> Datele personale colectate vor fi folosite conform <a class="buton-conditii showTerms" href="https://www.iqos.ro/privacy" target="_blank">Notificării de protecție a datelor</a>.</p>' +

                        '<a href="#" class="cookieHintSettings">AFLĂ MAI MULTE</a>' +
						'<a href="javascript:void(0);" class="saveCookies">ACCEPT TOATE</a>' +
					'</div>' +
				'</div>';

$(function() {
	$(document).on('click', '.disapprove', function(event) {
		event.preventDefault();
		$(".firstStep").hide();
		$(".secondStep").show();
	});

	$(document).on('click', '.ageGoBack', function(event) {
		event.preventDefault();
		$(".secondStep").hide();
		$(".firstStep").show();
	});

	$(document).on('click', '.approve', function(event) {
		$(".firstStep").addClass("isLoading");
	});

	// Trigger terms popup
	$("#agree").on("click", function(){
		if($("#agree").is(':checked')){
			$("#tandc").addClass("visible");
			if (screen.width < 770) { $(".hm-trigger").toggle(); }
			$("body").addClass("noScroll");
			$(".bg-overlay").show();
		}
	});

	// Close popup
	$(".close-pp").on("click", function(){
		$("#agree").prop('checked', false);
		$(".agree").attr('disabled', true);
		$(".terms").scrollTop(0);

		$(this).parent().parent().parent().parent().removeClass("visible");
		if (screen.width < 870) { $(".hm-trigger").toggle(); }

		$(".bg-overlay").hide();
		$("body").removeClass("noScroll");
	});

	// Check scroll
	if ($(".terms").length){
		$('.terms').bind('scroll',check_scroll);
	}

	// Go to bottom
	$(".go_bottom").on("click", function(){
		$('.terms').scrollTop($('.terms')[0].scrollHeight);
	});


/* =============================================================================
	o   CREATE / ACCEPT / HIDE COOKIE
============================================================================= */

	// if(!(readCookie('cookies_accepted'))) {
	// 	createCookie("dw_Technical_cookie", 'opt-in', 333);
	// 	createCookie("dw_Advertisement_cookie", 'opt-out', 333);
	// }

	$('body').on('click', '.cookieHintSettings', function (e) {
		e.preventDefault();

		if(readCookie('dw_Technical_cookie') === 'opt-in'){
			$("input[name='performance']").prop('checked', true);
		} else {
			$("input[name='performance']").prop('checked', false);
		}

		if(readCookie('dw_Advertisement_cookie') === 'opt-in'){
			$("input[name='advertising']").prop('checked', true);
		} else {
			$("input[name='advertising']").prop('checked', false);
		}

		$('#cookieModal').show();
		$('.bg-overlay').fadeIn();
		$('body').addClass('noScroll');
	});

	$('body').on('click', '.closeDisclaimer', function (e) {
		$('#cookieModal').hide();

		createCookie("cookies_accepted", 1, 333);
		$('body').removeClass('noScroll');
		$(".cookieDisclaimer").hide();
		$('.bg-overlay').fadeOut();

		if($('input[name="performance"]').is(":checked")){
			createCookie("dw_Technical_cookie", 'opt-in', 333);
		} else {
			createCookie("dw_Technical_cookie", 'opt-out', 333);
		}

		if($('input[name="advertising"]').is(":checked")){
			createCookie("dw_Advertisement_cookie", 'opt-in', 333);
		} else {
			createCookie("dw_Advertisement_cookie", 'opt-out', 333);
		}
	});

	$('body').on('click', '.saveCookies', function (e) {
		e.preventDefault();

		createCookie("cookies_accepted", 1, 333);
		$(".cookieDisclaimer").hide();

		// If users accepts from beginning
		if(!($(this).hasClass('button'))) {
			createCookie("dw_Technical_cookie", 'opt-in', 333);
			createCookie("dw_Advertisement_cookie", 'opt-in', 333);

			dataLayer.push({'event': 'cookieSettings','cookieType': 'advertising','cookieValue': 'opt-in'});
			dataLayer.push({'event': 'cookieSettings','cookieType': 'performance','cookieValue': 'opt-in'});
		} else {
			// If he chooses
			if($('input[name="performance"]').is(":checked")){
				createCookie("dw_Technical_cookie", 'opt-in', 333);

				dataLayer.push({'event': 'cookieSettings','cookieType': 'performance','cookieValue': 'opt-in'});
				// Krux('ns:philipmorris', 'consent:set', {dc: 1,al: 1,tg: 1,cd: 1,sh: 0,re: 0});
			} else {
				createCookie("dw_Technical_cookie", 'opt-out', 333);

				dataLayer.push({'event': 'cookieSettings','cookieType': 'performance','cookieValue': 'opt-out'});
				// Krux('ns:philipmorris', 'consent:set', {dc: 0,al: 0,tg: 0,cd: 0,sh: 0,re: 0});
			}

			if($('input[name="advertising"]').is(":checked")){
				createCookie("dw_Advertisement_cookie", 'opt-in', 333);

				dataLayer.push({'event': 'cookieSettings','cookieType': 'advertising','cookieValue': 'opt-in'});
				// Krux('ns:philipmorris', 'consent:set', {dc: 1,al: 1,tg: 1,cd: 1,sh: 0,re: 0});
			} else {
				createCookie("dw_Advertisement_cookie", 'opt-out', 333);

				dataLayer.push({'event': 'cookieSettings','cookieType': 'advertising','cookieValue': 'opt-out'});
				// Krux('ns:philipmorris', 'consent:set', {dc: 0,al: 0,tg: 0,cd: 0,sh: 0,re: 0});
			}
		}

		$('#cookieModal').hide();
		$('body').removeClass('noScroll');
		$('.bg-overlay').fadeOut();
	});

	$(".close_btn").on("click", function(){
		$('#cookieModal').hide();

		$('body').removeClass('noScroll');
		$('.bg-overlay').fadeOut();
	});


	// if(!(readCookie('cookies_accepted'))) {
	// 	if ((window.location.hostname === 'localhost') || (window.location.hostname === '127.0.0.1') || (window.location.hostname === 'sandbox.gd.ro') || (window.location.hostname === 'sandbox2.gd.ro')) {
	//
	// 	} else {
	// 		$(disclaimer).prependTo($("body"));
	// 		$(".cookieDisclaimer").show();
	// 	}
	// }

	$(".acceptTerms").click(function (e) {
		e.preventDefault();
		$("#cookieModal").hide();

		$(".cookieDisclaimer").hide();

		createCookie("cookies_accepted", 1, 333);
		$('body').removeClass('noScroll');
		$(".cookieDisclaimer").hide();
		$('.bg-overlay').fadeOut();

		if($('input[name="performance"]').is(":checked")){
			createCookie("dw_Technical_cookie", 'opt-in', 333);

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event': 'cookieSettings',
				'cookieType': 'performance',
				'cookieValue': 'opt-in',
			});
		} else {
			createCookie("dw_Technical_cookie", 'opt-out', 333);

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event': 'cookieSettings',
				'cookieType': 'performance',
				'cookieValue': 'opt-out',
			});
		}

		if($('input[name="advertising"]').is(":checked")){
			createCookie("dw_Advertisement_cookie", 'opt-in', 333);

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event': 'cookieSettings',
				'cookieType': 'advertising',
				'cookieValue': 'opt-in',
			});
		} else {
			createCookie("dw_Advertisement_cookie", 'opt-out', 333);

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event': 'cookieSettings',
				'cookieType': 'advertising',
				'cookieValue': 'opt-out',
			});
		}
	});


	// Select all links with hashes
	$('a[href*="#"]')
	// Remove links that don't actually link to anything
	.not('[href="#"]')
	.not('[href="#0"]')
	.click(function (event) {
		  // On-page links
		  if (
			location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
			&&
			location.hostname == this.hostname
			) {
			  // Figure out element to scroll to
		  var target = $(this.hash)
		  target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
			  // Does a scroll target exist?
			  if (target.length) {
				  // Only prevent default if animation is actually gonna happen
				  event.preventDefault()
				  $('html, body').animate({
					scrollTop: target.offset().top
				  }, 1000, function () {
					  // Callback after animation
					  // Must change focus!
					  var $target = $(target)
					  $target.focus()
					  if ($target.is(':focus')) { // Checking if the target was focused
						return false
					  } else {
						  $target.attr('tabindex', '-1') // Adding tabindex for elements not focusable
						  $target.focus() // Set focus again
					  }

				  })
			  }
		  }
	  });

	$("body").append("<div class=\"bg-overlay\"></div>");

	// Send GTM Events on button clicks
	$(".freeTestCTA").click(function () {
		dataLayer.push({
			'event':'gtmZoneEvent',
			'eventCategory': 'Clicks',
			'eventAction': 'Try_Sticky_Element',
			'eventLabel': 'Form_Anchor_Sticky_Element',
			'eventNonInteraction': 'false'
		});
	});

	$(".tryCTA").click(function () {
		dataLayer.push({
			'event':'gtmZoneEvent',
			'eventCategory': 'Clicks',
			'eventAction': 'Try_Orange_Button',
			'eventLabel': 'Form_Anchor_Orange',
			'eventNonInteraction': 'false'
		});
	});
});

